<template>
  <div>
    <vx-card class="mb-10">
      <h2>Observed change in children across core skills</h2>
      <div class="con-tablex vs-table--content border-less-table">
        <table width="100%" class="mb-10">
          <thead>
            <tr class="text-center">
              <th></th>
              <th v-for="(coreSkill, key) in currentWeekCoreSkills" :key="key">
                <span class="img-block">
                  <img
                    :src="coreSkill.icon ? coreSkill.icon : $defaultImage"
                    :alt="coreSkill.name"
                    class="product-img"
                  />
                </span>
                {{ coreSkill.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>This week</strong>
              </td>
              <td
                v-for="(coreSkill, key) in currentWeekCoreSkills"
                :set="
                  (s =
                    coreSkill.activities && coreSkill.activities.totalGrowth
                      ? Number(coreSkill.activities.totalGrowth.toFixed())
                      : 0)
                "
                :key="key"
                class="relative"
              >
                {{ s }}
                <i
                  v-if="
                    s >
                      (lastWeekCoreSkills[key].activities
                        ? lastWeekCoreSkills[key].activities.totalGrowth
                        : 0)
                  "
                  class="fa fa-caret-up fa-2x success absolute ml-3"
                ></i>
                <i
                  v-if="
                    s <
                      (lastWeekCoreSkills[key].activities
                        ? lastWeekCoreSkills[key].activities.totalGrowth
                        : 0)
                  "
                  class="fa fa-caret-down fa-2x danger absolute ml-3"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Last Week</strong>
              </td>
              <td
                v-for="(coreSkill, key) in lastWeekCoreSkills"
                :set="
                  (a =
                    coreSkill.activities && coreSkill.activities.totalGrowth
                      ? Number(coreSkill.activities.totalGrowth.toFixed())
                      : 0)
                "
                :key="key"
                class="relative"
              >
                {{ a }}
                <i
                  v-if="
                    a >
                      (currentWeekCoreSkills[key].activities
                        ? currentWeekCoreSkills[key].activities.totalGrowth
                        : 0)
                  "
                  class="fa fa-caret-up fa-2x success absolute ml-3"
                ></i>
                <i
                  v-if="
                    a <
                      (currentWeekCoreSkills[key].activities
                        ? currentWeekCoreSkills[key].activities.totalGrowth
                        : 0)
                  "
                  class="fa fa-caret-down fa-2x danger absolute ml-3"
                ></i>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Average</strong>
              </td>
              <td v-for="(coreSkill, key) in averageCoreSKills" :key="key">
                {{
                  coreSkill.weeklyAverage
                    ? Number(coreSkill.weeklyAverage.toFixed())
                    : 0
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </vx-card>
    <vx-card>
      <h2>Professional development</h2>
      <div class="con-tablex vs-table--content">
        <table v-if="currentWeekCoreSkills">
          <tr v-for="(coreSkill, key) in currentWeekCoreSkills" :key="key">
            <td>
              <div class="demo-alignment">
                <img
                  :src="coreSkill.icon ? coreSkill.icon : $defaultImage"
                  width="45"
                  height="45"
                  class="inline-block"
                  style="object-fit: scale-down"
                />
                {{ coreSkill.name }}
              </div>
            </td>
            <td>
              <a href="#" class="text-center" @click="detailPage(coreSkill._id)"
                >{{ coreSkill.name }} - Professional Developments</a
              >
            </td>
          </tr>
        </table>
      </div>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      fromSidebarData: {},
      roomIdData: null,
      centerId: null,
      currentWeekCoreSkills: [],
      lastWeekCoreSkills: [],
      averageCoreSKills: []
    };
  },
  props: {
    fromSidebar: {},
    roomId: "",
    roomIds: {},
    teacherIds: {},
    childrenIds: {}
  },
  methods: {
    ...mapActions("activity", [
      "getActivityGrowth",
      "getCenterAdminActivityGrowth"
    ]),
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    async getCoreSkills(roomId) {
      this.$vs.loading();
      let query = {
        roomId, childId:this.childrenIds[0]
      }
      await this.getActivityGrowth(query)
        .then(response => {
          this.currentWeekCoreSkills = response.data.data.currentWeek;
          this.lastWeekCoreSkills = response.data.data.lastWeek;
          this.averageCoreSKills = response.data.data.averageWeek;
          this.$vs.loading.close();
        })
        .catch(err => console.log(err));
    },
    async getCoreSkills2(query) {
      this.$vs.loading();
      await this.getCenterAdminActivityGrowth(query)
        .then(response => {
          this.currentWeekCoreSkills = response.data.data.currentWeek;
          this.lastWeekCoreSkills = response.data.data.lastWeek;
          this.averageCoreSKills = response.data.data.averageWeek;
          this.$vs.loading.close();
        })
        .catch(err => console.log(err));
    },
    async getDefaultCoreSkillList() {
      await this.fetchCoreSkills()
        .then(result => {
          let data = _.sortBy(result.data.data, [
            function(o) {
              return o.name;
            }
          ]);
          this.currentWeekCoreSkills = data;
          this.lastWeekCoreSkills = data;
          this.averageCoreSKills = data;
        })
        .catch(err => console.log(err));
    },
    getAverage(val) {
      return Math.round(val);
    },
    detailPage(coreSkillId) {
      this.$router.push({
        name: "center-admin-professional-development-detail",
        params: { coreSkillId: coreSkillId, rating: 1 }
      });
    }
  },
  mounted() {
    this.roomIdData = this.roomId;
    this.fromSidebarData = this.fromSidebar;
  },
  watch: {
    roomIdData(roomId) {
      if (roomId) {
        this.roomId = roomId;
        this.getCoreSkills(this.roomId);
      }
    },
    fromSidebarData(obj) {
      if (obj) {
        this.centerId = obj.value;
      }
    },
    roomIds(res) {
      let data = {
        roomIds: res
      };
      if (res.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
      } else {
        this.getDefaultCoreSkillList();
      }
    },
    teacherIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: res
      };
      if (this.roomIds.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
      }
    },
    childrenIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: this.teacherIds,
        childrenIds: res
      };
      if (this.roomIds.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
      }
    }
  }
};
</script>
