<template>
  <vx-card>
    <vs-row class="bg-grid-color mb-4">
      <vs-col
        vs-type="flex"
        vs-lg-justify="start"
        vs-sm-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="12"
      >
        <h2>Fast Checks</h2>

      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-sm-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="12"
      >
        <ul class="ml-auto status-list">
          <li>
            <span class="rounded-full" style="background: #74cac2;"></span>
            <span class="font-regular">Individual Average</span>
          </li>
          <li>
            <span class="rounded-full" style="background: #27378E;"></span>
            <span class="font-regular">Room/Centre Trend</span>
          </li>
        </ul>
      </vs-col>
    </vs-row>
    <div class="con-tablex vs-table--content">
      <table width="100%">
        <thead>
        <tr>
          <th style="width: 30%">Fast Check Comparison</th>
          <th>Professional Development</th>
          <th>Achieving</th>
          <th>Developing</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(fastCheck, key) in fastChecks" :key="key">
          <td>
            <div class="flex w-full justify-between">
              <span>{{ fastCheck.presetDetail.name }}</span>
              <!--<span>Average: <strong>{{ fastCheck.average }}</strong></span>-->
            </div>
            <ul class="rank-bar">
              <!-- <li>
                <span v-if="fastCheck.average===0" class="tag average-tag"></span>
                
                <span v-if="fastCheck.trend===0" class="tag trend-tag"
                      :style="fastCheck.average ===0 && fastCheck.trend === 0 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 '  : ''"></span>
              </li> -->
              <li>
                <span v-if="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed())===1" class="tag average-tag"></span>
                <span  style="position:absolute;top:8px;">1</span>
                <span v-if="Number(fastCheck.trend.toFixed())===1" class="tag trend-tag"
                      :style="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed()) ===1 && Number(fastCheck.trend.toFixed()) === 1 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 ' : ''"></span>
              </li>
              <li>
                <span v-if="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed())===2" class="tag average-tag"></span>
                <span  style="position:absolute;top:8px;">2</span>
                <span v-if="Number(fastCheck.trend.toFixed()) === 2" class="tag trend-tag"
                      :style="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed()) ===2 && Number(fastCheck.trend.toFixed()) === 2 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 ' : ''"></span>
              </li>
              <li>
                <span v-if="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed())===3" class="tag average-tag"></span>
                <span  style="position:absolute;top:8px;">3</span>
                <span v-if="Number(fastCheck.trend.toFixed())===3" class="tag trend-tag"
                      :style="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed()) ===3 && Number(fastCheck.trend.toFixed()) === 3 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 ' : ''"></span>
              </li>
              <li>
                <span v-if="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed())===4" class="tag average-tag"></span>
                <span  style="position:absolute;top:8px;">4</span>
                <span v-if="Number(fastCheck.trend.toFixed())===4" class="tag trend-tag"
                      :style="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed()) ===4 && Number(fastCheck.trend.toFixed()) === 4 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 ' : ''"></span>
              </li>
              <li>
                <span v-if="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed())===5" class="tag average-tag"></span>
                <span  style="position:absolute;top:8px;">5</span>
                <span v-if="Number(fastCheck.trend.toFixed())===5" class="tag trend-tag"
                      :style="fastCheck.testAverageOfChildren && Number(fastCheck.testAverageOfChildren.toFixed()) ===5 && Number(fastCheck.trend.toFixed()) === 5 ? 'border:5px solid #27378E !important;  top:-14px !important;background:#74cac2 ' : ''"></span>
              </li>

            </ul>
          </td>
          <td><a href="javascript:;"
                 class="font-semibold"
                 @click="detailPage(fastCheck.presetDetail._id, fastCheck.average ? fastCheck.average : 1)">
            {{ fastCheck.presetDetail.name }} - Professional Development</a></td>
          <td>
            <ul>
              <li style="display: inline-block" v-for="(child, key) in getTopThreeChildren(fastCheck.childs)"
                  :key="key">
                <router-link
                  :to="{ name: 'center-admin-children-view',params:{id: child.childDetail._id}}"
                  class="nav-link d-flex align-items-center active"
                >
                  <vs-avatar size="50px" :src="child.childDetail.photo" :alt="child.childDetail.fullName"
                             :title="child.childDetail.fullName"/>
                </router-link>
              </li>
            </ul>
          </td>
          <td>
            <ul>
              <li style="display: inline-block" v-for="(child, key) in getBottomThreeChildren(fastCheck.childs)"
                  :key="key">
                <router-link
                  :to="{ name: 'center-admin-children-view',params:{id: child.childDetail._id}}"
                  class="nav-link d-flex align-items-center active"
                >
                  <vs-avatar size="50px" :src="child.childDetail.photo" :alt="child.childDetail.fullName"
                             :title="child.childDetail.fullName"/>
                </router-link>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </vx-card>
</template>

<script>
import Vue from 'vue';
import {mapActions} from "vuex";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, {name: 'custom', lodash: lodash})

export default {
  data() {
    return {
      fastChecks: [],
      roomIdData: null,
      teacherIdData: null
    }
  },
  props: {
    fromSidebar: {},
    roomId: null,
    teacherId: null,
    roomIds: {},
    teacherIds: {},
    childrenIds: {},
    childrenList:{}
  },
  methods: {
    ...mapActions("fastCheck", [
      "fetchFastCheckCompare",
      "fetchCenterAdminFastCheckCompare"
    ]),
    ...mapActions("center", [
      "fetchTeacherByRoomIds"
    ]),
    getTopThreeChildren(children) {
      let data = lodash.orderBy(children, ['childAverage'], ['desc']);
      return lodash.take(data, 3)
    },
    detailPage(fastCheckPresetId, rating) {
      let routeData = this.$router.resolve({
        name: 'center-admin-fast-check-document',
        params: {fastCheckPresetId: fastCheckPresetId, rating: rating}
      });
      window.open(routeData.href, '_blank');
    },
    getBottomThreeChildren(children) {
      let a = lodash.orderBy(children, ['childAverage'], ['asc']);
      return lodash.take(a, 3)
    },
    async getFastCheck(roomId, teacherId) {
      this.$vs.loading();
      const data = {
        roomId: roomId,
        teacherId: teacherId
      };

      await this.fetchFastCheckCompare(data).then((res) => {
        this.$vs.loading.close();
        this.fastChecks = res.data.data;
      }).catch((err) => {
        this.$vs.loading.close();
      })
      this.$vs.loading.close();
    },
    async getFastCheck2(query) {
      this.$vs.loading();
      await this.fetchCenterAdminFastCheckCompare(query).then((res) => {
        this.$vs.loading.close();
        this.fastChecks = res.data.data;
      }).catch((err) => {
        this.$vs.loading.close();
      })
      this.$vs.loading.close();
    }

  },
  mounted() {
    this.teacherIdData = this.teacherId;
    this.roomIdData = this.roomId;
    if(this.childrenIds.length <= 0){
      this.$emit("loadChild",this.childrenList[0]._id);
    }

    let data = {
        roomIds: this.roomIds,
        teacherIds: this.teacherIds,
        childrenIds: this.childrenIds,
      };
      this.getFastCheck2(JSON.stringify(data));
    //this.getFastCheck(this.roomIdData, this.teacherIdData);
  },
  watch: {
    roomId(roomId) {
      if (roomId) {
        this.getFastCheck(roomId, this.teacherIdData);
      }
    },
    teacherId(teacherId) {
      if (teacherId) {
        this.getFastCheck(this.roomIdData, teacherId);
      }
    },

    fromSidebarData(obj) {
      if (obj) {
        console.log(obj)
      }
    },
    async roomIds(res) {

      const teachers = await this.fetchTeacherByRoomIds(JSON.stringify(res));
      let teachersIds = [];
      if (teachers.data.data.length > 0) {
        teachersIds = await teachers.data.data.map((item) => {
          return item._id;
        });
      }
      let data = {
        roomIds: res,
        teacherIds: teachersIds,
        childrenIds: this.childrenIds
      };
      await this.getFastCheck2(JSON.stringify(data));

    },
    teacherIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: res,
        childrenIds: this.childrenIds
      };
      this.getFastCheck2(JSON.stringify(data));
    },
    childrenIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: this.teacherIds,
        childrenIds: res
      };
      this.getFastCheck2(JSON.stringify(data));
    }
  },
}
</script>
<style lang="scss">

.tag {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: inline-block;

  &.average-tag {
    background: #74cac2;
  }

  &.trend-tag {
    background: #27378e;
  }
  
}



.rank-bar {
  background: #ededed;
  height: 6px;
  border-radius: 3px;
  display: flex;
  width: 100%;
  margin: 30px 0 0;
  @media (min-width: 901px) {
    margin: 50px 0;
  }

  li {
    width: calc(100% / 4);
    position: relative;

    .tag {
      position: absolute;
      top: -8px;
      right: 0;
    }
  }

  li:last-child {
    width: 2%;
  }
}
</style>
