var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',{staticClass:"mb-10"},[_c('h2',[_vm._v("Observed change in children across core skills")]),_c('div',{staticClass:"con-tablex vs-table--content border-less-table"},[_c('table',{staticClass:"mb-10",attrs:{"width":"100%"}},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th'),_vm._l((_vm.currentWeekCoreSkills),function(coreSkill,key){return _c('th',{key:key},[_c('span',{staticClass:"img-block"},[_c('img',{staticClass:"product-img",attrs:{"src":coreSkill.icon ? coreSkill.icon : _vm.$defaultImage,"alt":coreSkill.name}})]),_vm._v(" "+_vm._s(coreSkill.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v("This week")])]),_vm._l((_vm.currentWeekCoreSkills),function(coreSkill,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s =
                  coreSkill.activities && coreSkill.activities.totalGrowth
                    ? Number(coreSkill.activities.totalGrowth.toFixed())
                    : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                  _vm.s >
                    (_vm.lastWeekCoreSkills[key].activities
                      ? _vm.lastWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                  _vm.s <
                    (_vm.lastWeekCoreSkills[key].activities
                      ? _vm.lastWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Last Week")])]),_vm._l((_vm.lastWeekCoreSkills),function(coreSkill,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.a =
                  coreSkill.activities && coreSkill.activities.totalGrowth
                    ? Number(coreSkill.activities.totalGrowth.toFixed())
                    : 0)}},[_vm._v(" "+_vm._s(_vm.a)+" "),(
                  _vm.a >
                    (_vm.currentWeekCoreSkills[key].activities
                      ? _vm.currentWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                  _vm.a <
                    (_vm.currentWeekCoreSkills[key].activities
                      ? _vm.currentWeekCoreSkills[key].activities.totalGrowth
                      : 0)
                )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Average")])]),_vm._l((_vm.averageCoreSKills),function(coreSkill,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(coreSkill.weeklyAverage ? Number(coreSkill.weeklyAverage.toFixed()) : 0)+" ")])})],2)])])])]),_c('vx-card',[_c('h2',[_vm._v("Professional development")]),_c('div',{staticClass:"con-tablex vs-table--content"},[(_vm.currentWeekCoreSkills)?_c('table',_vm._l((_vm.currentWeekCoreSkills),function(coreSkill,key){return _c('tr',{key:key},[_c('td',[_c('div',{staticClass:"demo-alignment"},[_c('img',{staticClass:"inline-block",staticStyle:{"object-fit":"scale-down"},attrs:{"src":coreSkill.icon ? coreSkill.icon : _vm.$defaultImage,"width":"45","height":"45"}}),_vm._v(" "+_vm._s(coreSkill.name)+" ")])]),_c('td',[_c('a',{staticClass:"text-center",attrs:{"href":"#"},on:{"click":function($event){return _vm.detailPage(coreSkill._id)}}},[_vm._v(_vm._s(coreSkill.name)+" - Professional Developments")])])])}),0):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }