var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"learning-stories-card"},[_c('h2',[_vm._v("Core skills")]),_c('div',{staticClass:"con-tablex vs-table--content border-less-table mb-10"},[_c('table',{staticClass:"mb-5",attrs:{"width":"100%"}},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th'),_vm._l((_vm.currentWeekCoreSkills),function(coreSkill,key){return _c('th',{key:key},[_c('span',{staticClass:"img-block"},[_c('img',{staticClass:"product-img",attrs:{"src":coreSkill.icon ? coreSkill.icon : _vm.$defaultImage,"alt":coreSkill.name}})]),_vm._v(" "+_vm._s(coreSkill.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-left"},[_c('strong',[_vm._v("This week")])]),_vm._l((_vm.currentWeekCoreSkills),function(coreSkill,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s = coreSkill.activities
                ? coreSkill.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                _vm.s >
                  (_vm.lastWeekCoreSkills[key].activities
                    ? _vm.lastWeekCoreSkills[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.s <
                  (_vm.lastWeekCoreSkills[key].activities
                    ? _vm.lastWeekCoreSkills[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',{staticClass:"text-left"},[_c('strong',[_vm._v("Last Week")])]),_vm._l((_vm.lastWeekCoreSkills),function(coreSkill,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.a = coreSkill.activities
                ? coreSkill.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.a)+" "),(
                _vm.a >
                  (_vm.currentWeekCoreSkills[key].activities
                    ? _vm.currentWeekCoreSkills[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.a <
                  (_vm.currentWeekCoreSkills[key].activities
                    ? _vm.currentWeekCoreSkills[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',{staticClass:"text-left"},[_c('strong',[_vm._v("Average")])]),_vm._l((_vm.averageCoreSKills),function(coreSkill,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(_vm.getAverage(coreSkill.average ? coreSkill.average : 0))+" ")])})],2)])])]),_c('h2',[_vm._v("Ways of learning")]),_c('div',{staticClass:"con-tablex vs-table--content border-less-table mb-10"},[_c('table',{staticClass:"mb-5",attrs:{"width":"100%"}},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th'),_vm._l((_vm.currentWeekWaysOfLearnings),function(waysOfLearning,key){return _c('th',{key:key},[_c('span',{staticClass:"img-block"},[_c('img',{staticClass:"product-img",attrs:{"src":waysOfLearning.icon ? waysOfLearning.icon : _vm.$defaultImage,"alt":waysOfLearning.name}})]),_vm._v(" "+_vm._s(waysOfLearning.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v("This week")])]),_vm._l((_vm.currentWeekWaysOfLearnings),function(waysOfLearning,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s = waysOfLearning.activities
                ? waysOfLearning.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                _vm.s >
                  (_vm.lastWeekWaysOfLearnings[key].activities
                    ? _vm.lastWeekWaysOfLearnings[key].activities
                        .numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.s <
                  (_vm.lastWeekWaysOfLearnings[key].activities
                    ? _vm.lastWeekWaysOfLearnings[key].activities
                        .numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Last Week")])]),_vm._l((_vm.lastWeekWaysOfLearnings),function(waysOfLearning,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s = waysOfLearning.activities
                ? waysOfLearning.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                _vm.s >
                  (_vm.currentWeekWaysOfLearnings[key].activities
                    ? _vm.currentWeekWaysOfLearnings[key].activities
                        .numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.s <
                  (_vm.currentWeekWaysOfLearnings[key].activities
                    ? _vm.currentWeekWaysOfLearnings[key].activities
                        .numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Average")])]),_vm._l((_vm.averageWaysOfLearnings),function(waysOfLearning,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(_vm.getAverage(waysOfLearning.average ? waysOfLearning.average : 0))+" ")])})],2)])])]),_c('h2',[_vm._v("STREAM")]),_c('div',{staticClass:"con-tablex vs-table--content border-less-table"},[_c('table',{staticClass:"mb-5",attrs:{"width":"100%"}},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th'),_vm._l((_vm.currentWeekStream),function(stream,key){return _c('th',{key:key},[_c('span',{staticClass:"img-block"},[_c('img',{staticClass:"product-img",attrs:{"src":stream.icon ? stream.icon : _vm.$defaultImage,"alt":stream.name}})]),_vm._v(" "+_vm._s(stream.name)+" ")])})],2)]),_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v("This week")])]),_vm._l((_vm.currentWeekStream),function(stream,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s = stream.activities
                ? stream.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                _vm.s >
                  (_vm.lastWeekStream[key].activities
                    ? _vm.lastWeekStream[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.s <
                  (_vm.lastWeekStream[key].activities
                    ? _vm.lastWeekStream[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Last Week")])]),_vm._l((_vm.lastWeekStream),function(stream,key){return _c('td',{key:key,staticClass:"relative",attrs:{"set":(_vm.s = stream.activities
                ? stream.activities.numberOfActivities
                : 0)}},[_vm._v(" "+_vm._s(_vm.s)+" "),(
                _vm.s >
                  (_vm.currentWeekStream[key].activities
                    ? _vm.currentWeekStream[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-up fa-2x success absolute ml-3"}):_vm._e(),(
                _vm.s <
                  (_vm.currentWeekStream[key].activities
                    ? _vm.currentWeekStream[key].activities.numberOfActivities
                    : 0)
              )?_c('i',{staticClass:"fa fa-caret-down fa-2x danger absolute ml-3"}):_vm._e()])})],2),_c('tr',[_c('td',[_c('strong',[_vm._v("Average")])]),_vm._l((_vm.averageStream),function(stream,key){return _c('td',{key:key},[_vm._v(" "+_vm._s(_vm.getAverage(stream.average ? stream.average : 0))+" ")])})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }