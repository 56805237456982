<template>
  <div>
    <!--   room list-->
    <vx-card class="mb-base">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Room</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedRooms.length !== totalRoomIds.length"
            class="ml-auto"
            @click="selectAllRooms"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllRooms">
            Unselect All
          </vs-button>
          <vs-button
            color="primary"
            type="filled"
            class="ml-5"
            @click="addRoomHandler"
            >Add room</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="room-list mb-4" v-if="roomList.length">
        <li
          v-for="(room, index) in roomList"
          v-bind:key="index"
          :class="selectedRooms.includes(room._id) ? 'active' : ''"
        >
          <vs-avatar size="large" :src="room.logo" @click="selectRoom(room)" />
          <h3
            class="h5"
            style="color: #289AD5; font-size: 14px; line-height: 18px;"
          >
            {{ room.name }}
          </h3>
        </li>
      </ul>
      <span v-else>
        No Room found.
      </span>
      </horizontal-scroll>
    </vx-card>
    <!--teacher list-->
    <vx-card class="mb-base">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Teachers</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedTeachers.length !== totalTeacherIds.length"
            class="ml-auto"
            @click="selectAllTeachers"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllTeachers">
            Unselect All
          </vs-button>
          <vs-button
            color="primary"
            type="filled"
            class="ml-5"
            @click="addTeacherHandler"
            >Add teacher</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="room-list mb-4" v-if="teacherList.length">
        <li
          v-for="(teacher, index) in teacherList"
          v-bind:key="index"
          :class="selectedTeachers.includes(teacher._id) ? 'active' : ''"
        >
          <vs-avatar
            size="large"
            :src="teacher.profileImage"
            @click="selectTeacher(teacher, index)"
          />
          <h3
            class="h5"
            style="color: #289AD5; font-size: 14px; line-height: 18px;"
          >
            {{ firstNameOnly(teacher.fullName) }}
          </h3>
        </li>
      </ul>
      <span v-else>
        No Teachers in this room.
      </span>
      </horizontal-scroll>
    </vx-card>
    <!--children list-->
    <vx-card class="mb-base pb-8">
      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="mb-5"
      >
        <vs-col class="flex w-full sm:w-1/2 mb-5 md:mb-0">
          <h2 class="mb-0">Children</h2>
        </vs-col>
        <vs-col class="flex w-full md:w-1/2">
          <vs-button
            v-if="selectedChildren.length !== totalChildrenIds.length"
            class="ml-auto"
            @click="selectAllChildren"
          >
            Select All
          </vs-button>
          <vs-button v-else class="ml-auto" @click="unSelectAllChildren">
            Unselect All
          </vs-button>
          <vs-button
            color="primary"
            type="filled"
            class="ml-5"
            @click="addChildHandler"
            >Add child</vs-button
          >
        </vs-col>
      </vs-row>
      <!-- Avatar -->
      <horizontal-scroll>
      <ul class="room-list children-list mb-4" v-if="childrenList.length">
        <span v-for="(child, index) in childrenList" v-bind:key="index">
          <li
            :class="
              (selectedChildren.includes(child._id) ? 'active ' : '') +
                'relative inline-block mr-2'
            "
            @click="selectChild(child._id)"
          >
            <vs-avatar
              size="large"
              :src="child.photo"
              :title="child.fullName"
            />
            <span class="badge">
              {{
                "undefined" != typeof child.activities &&
                child.activities.length
                  ? child.activities[0].numberOfActivities
                  : 0
              }}</span
            >
            <p>{{firstNameOnly(child.fullName)}}</p>
          </li>
        </span>
      </ul>
      <span v-else>
        No children in this room.
      </span>
      </horizontal-scroll>
    </vx-card>

    <div class="mt-5 tab-wrapper">
      <vs-tabs class="activity-tab" v-model="activeTab">
        <vs-tab label="Learning stories">
          <div>
            <LearningStories
              :roomId="roomId"
              :childId="childId"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></LearningStories>
          </div>
        </vs-tab>
        <vs-tab label="Core skills">
          <div>
            <core-skill-tab
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></core-skill-tab>
          </div>
        </vs-tab>
        <vs-tab label="Fast checks">
          <div>
            <FastCheckTab
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :teacherId="teacherId"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
              :childrenList="childrenList"
              @loadChild="loadChild"
            >
            </FastCheckTab>
          </div>
        </vs-tab>
        <vs-tab label="Learning Framework">
          <div>
            <ElyfBlock
              :fromSidebar="fromSidebar"
              :roomId="roomId"
              :roomIds="selectedRooms"
              :teacherIds="selectedTeachers"
              :childrenIds="selectedChildren"
            ></ElyfBlock>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AddInsightSideBar from "./InsightSidebar";
import LearningStories from "./LearningStories";
import CoreSkillTab from "./CoreSkillTab";
import ElyfBlock from "./Eylf";
import FastCheckTab from "./FastCheckTab";
import _ from "lodash";
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
  data() {
    return {
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      roomList: "",
      teacherList: "",
      childrenList: "",
      activeRoomKey: 0,
      activeTeacherKey: 0,
      selectedRoom: "",
      selectedRoomName: "",
      selectedTeacher: "",
      roomData: [],
      teacherData: [],
      roomId: null,
      teacherId: null,
      childId: null,
      centerId: null,
      childrenData: [],
      sectionTitle: "",
      teacherName: "",
      addInsightSidebar: true,
      insightSidebarData: {},
      fromSidebar: {},
      dataTableParams: {
        country: "all",
        state: "all"
      },
      selectedRooms: [],
      selectedTeachers: [],
      selectedChildren: [],
      totalRoomIds: [],
      totalTeacherIds: [],
      totalChildrenIds: [],
      activeTab: 0
    };
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
    AddInsightSideBar,
    LearningStories,
    CoreSkillTab,
    ElyfBlock,
    FastCheckTab,
    HorizontalScroll
  },
  methods: {
    ...mapActions("centerAdmin", [
      "fetchCentreRoomList",
      "getAdminActivity",
      "learningStoryDataForDashboardAdmin",
      "fetchTeacherByRoomIds",
      "fetchChildrenByMultipleRooms"
    ]),
    ...mapActions("center", [
      "getAllCountries",
      "getCentreByRegion",
      "getDirectorCenterRoomsList"
    ]),
    ...mapActions("room", [
      "getRoomById",
      "getRoomsByDirectorId",
      "getTeachersByRoomId",
      "getChildrenByRoomId",
      "setSelectRooms"
    ]),
    firstNameOnly(fullName){
      let name = fullName.split(' ');
      return name[0];
    },
    loadChild(childId){
      this.selectedChildren.push(childId);
    },
    selectAllRooms() {
      this.selectedRooms = JSON.parse(JSON.stringify(this.totalRoomIds));
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);
    },
    unSelectAllRooms() {
      this.selectedRooms = [];
      this.teacherList = [];
      this.childrenList = [];
      this.selectedTeachers = [];
      this.selectedChildren = [];
    },
    selectAllTeachers() {
      this.selectedTeachers = JSON.parse(JSON.stringify(this.totalTeacherIds));
    },
    unSelectAllTeachers() {
      this.selectedTeachers = [];
    },
    selectAllChildren() {
      this.selectedChildren = JSON.parse(JSON.stringify(this.totalChildrenIds));
    },
    unSelectAllChildren() {
      this.selectedChildren = [];
    },
    async getRoomList(id) {
      this.$vs.loading();
      await this.fetchCentreRoomList(id).then(res => {
        this.roomList = res.data.data;
        this.totalRoomIds = res.data.data.map(item => {
          return item._id;
        });
        this.$vs.loading.close();
        if (this.roomList.length > 0) {
          this.roomId = this.roomList[0]._id;
          if (
            "undefined" != typeof this.$route.query.roomId &&
            "" != this.$route.query.roomId
          ) {
            this.roomId = this.$route.query.roomId;
            this.activeRoomKey = _.findIndex(this.roomList, [
              "_id",
              this.roomId
            ]);
          }
          this.activeTeacherKey = 0;
          this.getAllTeachersOfRoom(this.roomId, this.activeTeacherKey);
          this.getAllChildrenOfRoom(this.roomId);
        }
        this.selectedRoomName = this.roomList[this.activeRoomKey].name;
        this.selectedRooms.push(this.roomList[this.activeRoomKey]._id);
        if (this.$route.query && this.$route.query.from === "core-skill") {
          this.activeTab = 1;
        }
      });
    },
    async getAllTeachersOfMultipleRooms(roomIds) {
      const teachers = await this.fetchTeacherByRoomIds(
        JSON.stringify(roomIds)
      );
      this.totalTeacherIds = teachers.data.data.map(item => {
        return item._id;
      });

      this.teacherList = teachers.data.data;
    },
    getAllTeachersOfRoom(roomId, key) {
      this.selectedTeacher = "";
      this.getTeachersByRoomId(roomId)
        .then(async res => {
          this.activeTeacherKey = await key;
          this.teacherList = res.data.data;
          this.totalTeacherIds = res.data.data.map(item => {
            return item._id;
          });

          this.selectedTeacher = this.teacherList[
            this.activeTeacherKey
          ].fullName;
          this.teacherId = this.teacherList[this.activeTeacherKey]._id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAllChildrenOfRoom(roomId) {
      this.getChildrenByRoomId(roomId)
        .then(res => {
          this.childrenList = res.data.data;
          this.totalChildrenIds = res.data.data.map(item => {
            return item._id;
          });
          this.selectedChildren = [];
          this.selectedChildren.push(this.childrenList[0]._id);

        })
        .catch(err => {
          console.log(err);
        });
    },
    async getAllChildrenOfMultipleRoom(roomIds) {
      const children = await this.fetchChildrenByMultipleRooms(
        JSON.stringify(roomIds)
      );
      this.childrenList = children.data.data;
      this.totalChildrenIds = children.data.data.map(item => {
        return item._id;
      });

      this.selectedChildren = [];
      this.selectedChildren.push(this.childrenList[0]._id);
    },
    async selectRoom(val) {
      this.selectedRoom = val;
      if (this.selectedRooms.includes(val._id)) {
        if (this.selectedRooms.length === 1) {
          alert(
            "You cannot uncheck all rooms. You need to select at least one room."
          );
        } else {
          let index = _.findIndex(this.selectedRooms, function(o) {
            return o == val._id;
          });
          this.selectedRooms.splice(index, 1);
        }
      } else {
        this.selectedRooms.push(val._id);
      }
      // await this.setSelectRooms(val._id);
      this.getAllTeachersOfMultipleRooms(this.selectedRooms);
      this.getAllChildrenOfMultipleRoom(this.selectedRooms);
      this.$vs.loading.close();
    },
    selectTeacher(val, key) {
      if (this.selectedTeachers.includes(val._id)) {
        if (this.selectedTeachers.length < 2) {
          alert(
            "You cannot uncheck all teachers. You need to select at least one room."
          );
        } else {
          let index = _.findIndex(this.selectedTeachers, function(o) {
            return o == val._id;
          });
          this.selectedTeachers.splice(index, 1);
        }
      } else {
        this.selectedTeachers.push(val._id);
      }
      this.activeTeacherKey = key;
      this.selectedTeacher = val.fullName;
      // this.teacherId = val._id;
    },
    async selectChild(childId) {
      if (this.selectedChildren.includes(childId)) {
        if (this.selectedChildren.length === 1) {
          alert(
            "You cannot uncheck all children. You need to select at least one child."
          );
        } else {
          let index = _.findIndex(this.selectedChildren, function(o) {
            return o === childId;
          });
          this.selectedChildren.splice(index, 1);
        }
      } else {
        this.selectedChildren.push(childId);
      }
    },
    addRoomHandler() {
      this.$router.push({
        name: "center-admin-add-room",
        params: { centerId: this.centerId }
      });
    },
    addTeacherHandler() {
      this.$router.push({
        name: "center-admin-add-teacher",
        params: { centerId: this.centerId }
      });
    },
    addChildHandler() {
      this.$router.push({
        name: "center-admin-children-add"
      });
    },
    toggleInsightSidebar(val = false) {
      this.addInsightSidebar = val;
    },

    async refreshDataMain(obj = false) {
      if (obj) {
        this.fromSidebar = obj;
        let param = {
          search: "",
          id: obj.value
        };
        this.centerId = obj.value;

        this.$vs.loading();
        this.toggleInsightSidebar(false);

        await this.getRoomsOfCenter(param);

        this.$vs.loading.close();
      }
    }
  },
  watch: {
    activeTab(val) {
      // if (val != 1) {
      //   if (this.$route.query) {
      //     let query = Object.assign({}, this.$route.query);
      //     delete query.from;
      //     this.$router.replace({ query });
      //   }
      // }
    }
  },
  mounted() {},
  async created() {
    this.centerId = this.$store.state.AppActiveUser.learningCenterId;
    this.getRoomList(this.centerId);
    await this.getAllCountries().then(res => {
      this.countryOptions = this.$store.state.center.countryOptions;
    });
  }
};
</script>

<style lang="scss" scoped>
#avatar-col {
  width: 10rem;
}

.con-vs-avatar.large {
  width: 71px;
  height: 71px;
  @media (max-width: 576px) {
    width: 50px;
    height: 50px;
  }
}

.room-list {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}

.teacher-list {
  @media (max-width: 576px) {
    margin-bottom: 30px;
  }

  li {
    display: inline-block;
    text-align: center;
    max-width: 100px;
    margin-right: 40px;
    vertical-align: top;
    @media (max-width: 576px) {
      max-width: 82px;
      margin-right: 10px;
    }

    &.active {
      .con-vs-avatar.large {
        border: 4px solid #289ad5;
        @media (max-width: 576px) {
          border: 2px solid #289ad5;
        }
      }
    }
  }
}

.children-list .con-vs-avatar.large {
  width: 71px;
  height: 71px;
  margin-right: 19px;
  @media (min-width: 768px) {
    margin-right: 35px;
  }
}

.badge {
  background: #2898d5;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  font-size: 13px;
  font-weight: 600;
  line-height: 28px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: -3px;
  right: 10px;
  @media (min-width: 768px) {
    right: 25px;
  }
}

.teacher-list h3 {
  color: #289ad5;
  font-size: 14px;
  line-height: 18px;
}
</style>
