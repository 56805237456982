<template>
  <vx-card style="margin-bottom: 30px">
    <h2>Tracker</h2>
    <vs-row>
      <vs-col class="w-full md:w-1/2 md:pr-5 mb-10">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="timeSpentPlanningActivities"
          :series="timeSpentPlanningActivities.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pl-5 mb-10">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="numberOfActivityPlanned"
          :series="numberOfActivityPlanned.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pr-5 mb-10 md:mb-0">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="timeSpentActivityReporting"
          :series="timeSpentActivityReporting.series"
        ></vue-apex-charts>
      </vs-col>
      <vs-col class="w-full md:w-1/2 md:pl-5">
        <vue-apex-charts
          type="bar"
          height="350"
          :options="numberOfLearningStoriesCreated"
          :series="numberOfLearningStoriesCreated.series"
        ></vue-apex-charts>
      </vs-col>
    </vs-row>
  </vx-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { mapActions } from "vuex";
import Vue from "vue";
import VueLodash from "vue-lodash";
import _ from "lodash";

Vue.use(VueLodash, { name: "custom", lodash: _ });
export default {
  data() {
    return {
      currentWeek: 0,
      lastWeek: 0,
      average: 0,
      timeSpentPlanningActivities: {},
      numberOfActivityPlanned: {},
      timeSpentActivityReporting: {},
      numberOfLearningStoriesCreated: {}
    };
  },
  props: {
    roomIdTracker: {},
    roomIds: {},
    teacherIds: {},
    childrenIds: {}
  },
  components: {
    VueApexCharts
  },
  methods: {
    ...mapActions("activity", [
      "fetchWeeklyActivityBySlug",
      "fetchCenterAdminWeeklyActivityBySlug"
    ]),
    async getTrackerData(roomId) {


      await this.fetchWeeklyActivityBySlug(roomId)
        .then(async res => {
          let self = this;
          let response = await res.data.data;
          let a = [
            response.averagePlanActivity
              ? response.averagePlanActivity.average
              : 0
          ];
          let b = [
            response.averageLearningStories
              ? response.averageLearningStories.average
              : 0
          ];
          let c = [
            response.averagePlanActivityTimeTaken.length
              ? response.averagePlanActivityTimeTaken[0].averageTimeTaken
              : 0
          ];
          let d = [
            response.averageLearningStoriesTimeTaken.length
              ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken
              : 0
          ];
          a.push(
            response.lastWeekPlanActivity
              ? response.lastWeekPlanActivity.numberOfActivities
              : 0
          );
          a.push(
            response.currentWeekPlanActivity
              ? response.currentWeekPlanActivity.numberOfActivities
              : 0
          );
          b.push(
            response.lastWeekLearningStories
              ? response.lastWeekLearningStories.numberOfActivities
              : 0
          );
          b.push(
            response.currentWeekLearningStories
              ? response.currentWeekLearningStories.numberOfActivities
              : 0
          );
          c.push(
            response.lastWeekTimeSpentPlanning.length
              ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(2)
              : 0
          );
          c.push(
            response.currentWeekTimeSpentPlanning.length
              ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.lastWeekTimeSpentLearningStories.length
              ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.currentWeekTimeSpentLearningStories.length
              ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          let highestA = _.max(a, function(o) {
            return o;
          });
          highestA = parseInt(highestA);

          let highestB = _.max(b, function(o) {
            return o;
          });
          highestB = parseInt(highestB);

          let highestC = _.max(c, function(o) {
            return o;
          });
          highestC = parseFloat(highestC);

          let highestD = _.max(d, function(o) {
            return o;
          });
          highestD = parseFloat(highestD);

          self.numberOfActivityPlanned = {
            title: {
              text: "Number of Activities Planned ",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Activity Planned",
                data: [
                  {
                    x: "Average",
                    y: Math.ceil(
                      response.averagePlanActivity
                        ? response.averagePlanActivity.average
                        : 0
                    )
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekPlanActivity
                      ? response.lastWeekPlanActivity.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekPlanActivity
                      ? response.currentWeekPlanActivity.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestA == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestA + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.numberOfLearningStoriesCreated = {
            title: {
              text: "Number of Learning Stories Created",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Learning Stories Created",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStories
                      ? response.averageLearningStories.average
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekLearningStories
                      ? response.lastWeekLearningStories.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekLearningStories
                      ? response.currentWeekLearningStories.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestB == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestB + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentPlanningActivities = {
            title: {
              text: "Time Spent Planning Activities (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Planning Activities (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averagePlanActivityTimeTaken.length
                      ? response.averagePlanActivityTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentPlanning.length
                      ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentPlanning.length
                      ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestC == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestC + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentActivityReporting = {
            title: {
              text: "Time Spent Completing Learning Stories (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Completing Learning Stories (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStoriesTimeTaken.length
                      ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentLearningStories.length
                      ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentLearningStories.length
                      ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestD == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestD + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
        })
        .catch(err => console.log(err));
    },
    async getTrackerData2(query) {




      await this.fetchCenterAdminWeeklyActivityBySlug(query)
        .then(async res => {
          let self = this;
          let response = await res.data.data;
          let a = [
            response.averagePlanActivity
              ? response.averagePlanActivity.average
              : 0
          ];
          let b = [
            response.averageLearningStories
              ? response.averageLearningStories.average
              : 0
          ];
          let c = [
            response.averagePlanActivityTimeTaken.length
              ? response.averagePlanActivityTimeTaken[0].averageTimeTaken
              : 0
          ];
          let d = [
            response.averageLearningStoriesTimeTaken.length
              ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken
              : 0
          ];
          a.push(
            response.lastWeekPlanActivity
              ? response.lastWeekPlanActivity.numberOfActivities
              : 0
          );
          a.push(
            response.currentWeekPlanActivity
              ? response.currentWeekPlanActivity.numberOfActivities
              : 0
          );
          b.push(
            response.lastWeekLearningStories
              ? response.lastWeekLearningStories.numberOfActivities
              : 0
          );
          b.push(
            response.currentWeekLearningStories
              ? response.currentWeekLearningStories.numberOfActivities
              : 0
          );
          c.push(
            response.lastWeekTimeSpentPlanning.length
              ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(2)
              : 0
          );
          c.push(
            response.currentWeekTimeSpentPlanning.length
              ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.lastWeekTimeSpentLearningStories.length
              ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          d.push(
            response.currentWeekTimeSpentLearningStories.length
              ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                  2
                )
              : 0
          );
          let highestA = _.max(a, function(o) {
            return o;
          });
          highestA = parseInt(highestA);

          let highestB = _.max(b, function(o) {
            return o;
          });
          highestB = parseInt(highestB);

          let highestC = _.max(c, function(o) {
            return o;
          });
          highestC = parseFloat(highestC);

          let highestD = _.max(d, function(o) {
            return o;
          });
          highestD = parseFloat(highestD);

          self.numberOfActivityPlanned = {
            title: {
              text: "Number of Activities Planned",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Activity Planned",
                data: [
                  {
                    x: "Average",
                    y: Math.ceil(
                      response.averagePlanActivity
                        ? response.averagePlanActivity.average
                        : 0
                    )
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekPlanActivity
                      ? response.lastWeekPlanActivity.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekPlanActivity
                      ? response.currentWeekPlanActivity.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestA == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestA + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.numberOfLearningStoriesCreated = {
            title: {
              text: "Number of Learning Stories Created",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Learning Stories Created",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStories
                      ? response.averageLearningStories.average
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekLearningStories
                      ? response.lastWeekLearningStories.numberOfActivities
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekLearningStories
                      ? response.currentWeekLearningStories.numberOfActivities
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestB == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestB + 1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentPlanningActivities = {
            title: {
              text: "Time Spent Planning Activities (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Planning Activities (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averagePlanActivityTimeTaken.length
                      ? response.averagePlanActivityTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentPlanning.length
                      ? response.lastWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentPlanning.length
                      ? response.currentWeekTimeSpentPlanning[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestC == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestC + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
          self.timeSpentActivityReporting = {
            title: {
              text: "Time Spent Completing Learning Stories (Minutes)",
              align: "center",
              offsetY: 0,
              style: {
                fontSize: "14px",
                fontWeight: "bold",
                color: "#263238"
              }
            },
            series: [
              {
                name: "Time Spent Completing Learning Stories (Minutes)",
                data: [
                  {
                    x: "Average",
                    y: response.averageLearningStoriesTimeTaken.length
                      ? response.averageLearningStoriesTimeTaken[0].averageTimeTaken.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "Last Week",
                    y: response.lastWeekTimeSpentLearningStories.length
                      ? response.lastWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  },
                  {
                    x: "This Week",
                    y: response.currentWeekTimeSpentLearningStories.length
                      ? response.currentWeekTimeSpentLearningStories[0].timeTakenHour.toFixed(
                          2
                        )
                      : 0
                  }
                ]
              }
            ],
            chart: {
              type: "bar",
              height: highestD == 0 ?200:350,
              toolbar: {
                show: true,
                tools: {
                  download: false
                }
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                distributed: true,
                columnWidth: "100%",
                dataLabels: {
                  position: "top"
                }
              }
            },
            colors: ["#73C1FF", "#8684FF", "#70CAC2"],
            dataLabels: {
              enabled: true,
              style: {
                colors: ["#333"]
              },
              offsetY: -30
            },
            grid: {
              show: false
            },
            yaxis: {
              max: highestD + 0.1,
              title: {
                show: false
              },
              labels: {
                show: false
              }
            },
            legend: {
              show: false
            }
          };
        })
        .catch(err => console.log(err));
    },
    defaultTrackerChart() {
      let self = this;
      let a = [0];
      let b = [0];
      let c = [0];
      let d = [0];
      a.push(0);
      a.push(0);
      b.push(0);
      b.push(0);
      c.push(0);
      c.push(0);
      d.push(0);
      d.push(0);
      let highestA = _.max(a, function(o) {
            return o;
          });
          highestA = parseInt(highestA);

          let highestB = _.max(b, function(o) {
            return o;
          });
          highestB = parseInt(highestB);

          let highestC = _.max(c, function(o) {
            return o;
          });
          highestC = parseFloat(highestC);

          let highestD = _.max(d, function(o) {
            return o;
          });
          highestD = parseFloat(highestD);

      self.numberOfActivityPlanned = {
        title: {
          text: "Number of Activities Planned",
          align: "center",
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        series: [
          {
            name: "Activity Planned",
            data: [
              {
                x: "Average",
                y: Math.ceil(0)
              },
              {
                x: "Last Week",
                y: 0
              },
              {
                x: "This Week",
                y: 0
              }
            ]
          }
        ],
        chart: {
          type: "bar",
          height: highestA == 0 ?200:350,
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "100%",
            dataLabels: {
              position: "top"
            }
          }
        },
        colors: ["#73C1FF", "#8684FF", "#70CAC2"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          offsetY: -30
        },
        grid: {
          show: false
        },
        yaxis: {
          max: highestA + 1,
          title: {
            show: false
          },
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
      self.numberOfLearningStoriesCreated = {
        title: {
          text: "Number of Learning Stories Created",
          align: "center",
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        series: [
          {
            name: "Learning Stories Created",
            data: [
              {
                x: "Average",
                y: 0
              },
              {
                x: "Last Week",
                y: 0
              },
              {
                x: "This Week",
                y: 0
              }
            ]
          }
        ],
        chart: {
          type: "bar",
          height: highestB == 0 ?200:350,
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "100%",
            dataLabels: {
              position: "top"
            }
          }
        },
        colors: ["#73C1FF", "#8684FF", "#70CAC2"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          offsetY: -30
        },
        grid: {
          show: false
        },
        yaxis: {
          max: highestB + 1,
          title: {
            show: false
          },
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
      self.timeSpentPlanningActivities = {
        title: {
          text: "Time Spent Planning Activities (Minutes)",
          align: "center",
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        series: [
          {
            name: "Time Spent Planning Activities (Minutes)",
            data: [
              {
                x: "Average",
                y: 0
              },
              {
                x: "Last Week",
                y: 0
              },
              {
                x: "This Week",
                y: 0
              }
            ]
          }
        ],
        chart: {
          type: "bar",
          height: highestC == 0 ?200:350,
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "100%",
            dataLabels: {
              position: "top"
            }
          }
        },
        colors: ["#73C1FF", "#8684FF", "#70CAC2"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          offsetY: -30
        },
        grid: {
          show: false
        },
        yaxis: {
          max: highestC + 0.1,
          title: {
            show: false
          },
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
      self.timeSpentActivityReporting = {
        title: {
          text: "Time Spent Completing Learning Stories (Minutes)",
          align: "center",
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#263238"
          }
        },
        series: [
          {
            name: "Time Spent Completing Learning Stories (Minutes)",
            data: [
              {
                x: "Average",
                y: 0
              },
              {
                x: "Last Week",
                y: 0
              },
              {
                x: "This Week",
                y: 0
              }
            ]
          }
        ],
        chart: {
          type: "bar",
          height: highestD == 0 ?200:350,
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: true,
            columnWidth: "100%",
            dataLabels: {
              position: "top"
            }
          }
        },
        colors: ["#73C1FF", "#8684FF", "#70CAC2"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#333"]
          },
          offsetY: -30
        },
        grid: {
          show: false
        },
        yaxis: {
          max: highestD + 0.1,
          title: {
            show: false
          },
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        }
      };
    }
  },
  mounted() {
    if (null != this.roomIdTracker) {
      this.getTrackerData(this.roomIdTracker);
    }
  },
  computed: {},
  watch: {
    roomIdTracker(obj) {
      this.roomIdTracker = obj;
      if (null != this.roomIdTracker) {
        this.getTrackerData(this.roomIdTracker);
      }
    },
    roomIds(res) {
      if (res.length > 0) {
        let data = {
          roomIds: res
        };
        data = JSON.stringify(data);
        this.getTrackerData2(data);
      } else {
        this.defaultTrackerChart();
      }
    },
    teacherIds(res) {
      if (this.roomIds.length > 0) {
        let data = {
          roomIds: this.roomIds,
          teacherIds: res
        };
        data = JSON.stringify(data);
        this.getTrackerData2(data);
      }
    },

    // childrenIds(res) {
    //   if (this.roomIds.length > 0) {
    //     let data = {
    //       roomIds: this.roomIds,
    //       teacherIds: this.teacherIds,
    //       childrenIds: res
    //     };
    //     data = JSON.stringify(data);
    //     this.getTrackerData2(data);
    //   }
    // }
  }
};
</script>
