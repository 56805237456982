<template>
  <vs-sidebar
    click-not-close
    position-left
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    :hidden-background="true"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-end justify-end px-6 mt-6 mb-6">
      <span class="menu-icon" @click.stop="isSidebarActiveLocal = false">
        <feather-icon icon="XIcon" class="m-0 cursor-pointer" />Close
      </span>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6--">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="sidenav---" style="width:100%">
            <div class="flex items-center justify-between py-6">
              <h2 class="mb-0">Centres</h2>
            </div>
            <div class="my-8">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <!-- <label class="block mb-2">Country</label> -->
                  <v-select
                    :options="countryOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter"
                    class="mb-8 md:mb-0"
                  >
                    <template #header>
                      <label for class="label">Country</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="my-8">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <!-- <label class="block mb-2">Select Region</label> -->
                  <v-select
                    :options="stateOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter"
                    class="mb-8 md:mb-0"
                  >
                    <template #header>
                      <label for class="label">Select Region</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="my-8">
              <div class="select-wrapper">
                <div class="vs-component is-label-placeholder">
                  <!-- <label class="block mb-2">Select Centre</label> -->
                  <v-select
                    :options="centerOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="centerFilter"
                    class="mb-8 md:mb-0"
                  >
                    <template #header>
                      <label for class="label">Select Centre</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
        </vs-col>
      </div>
    </VuePerfectScrollbar>
  </vs-sidebar>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import _ from "lodash";

export default {
  data() {
    return {
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      /* dataTableParams: {
          country: "all",
          state: "all"
        },*/
      selectedCountry: "",
      selectedRegion: "",
      centreData: [],
      //learningCentreOptions: [],
      countryOptions: [],
      stateOptions: [],
      centerOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateFilter: { label: "All", value: "all" },
      centerFilter: { label: "All", value: "all" },
      //searchResults: null,
    };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    centerId: {
      type: String,
      default: "",
    },
  },
  components: {
    vSelect,
    VuePerfectScrollbar,
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.centerOptions = [];

      //this.centerFilter = {label: 'All', value: 'all'};
    },
    stateFilter(obj) {
      if ("all" != obj.value) {
        this.getLearningCenter();
      }
    },

    centerFilter(obj) {
      this.$emit("refreshDataMain", obj);
    },
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        /*  this.centerId = this.centerId
          this.userData.fullName = this.data.fullName
          this.userData.email = this.data.email
          this.userData.mobileNumber = this.data.mobileNumber
          this.userData.status = false */
        this.initValues();
      }
    },
  },
  computed: {
    director() {
      return this.$store.state.AppActiveUser;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
  },

  methods: {
    ...mapActions("center", [
      "getDirectorAllCountries",
      "getCentreByRegion",
      "getCentreByRegion",
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },
    initValues() {
      if (this.data._id) return;
      /*   this.fullName = ""
        this.email = ""
        this.mobileNumber = ""
        this.status = false */
      this.countryFilter = { label: "All", value: "all" };
      this.stateFilter = { label: "All", value: "all" };
    },
    getLearningCenter() {
      let self = this;
      let data = {
        country:
          this.countryFilter.value == "all" ? "" : this.countryFilter.value,
        state: this.stateFilter.value == "all" ? "" : this.stateFilter.value,
      };
      // this.$vs.loading()

      this.getCentreByRegion(data)
        .then((res) => {
          let options = [];
          _.forEach(res.data.data, function (element) {
            options.push({
              label: element.name,
              value: element._id,
              center: element.directorId,
            });
          });
          this.centerOptions = options;
        })
        .catch((err) => {});
    },
  },
  created() {
    this.getDirectorAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })
      .catch((err) => {
        //console.error(err)
      });
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
