<template>
  <vx-card class="learning-stories-card">
    <h2>Core skills</h2>
    <div class="con-tablex vs-table--content border-less-table mb-10">
      <table width="100%" class="mb-5">
        <thead>
          <tr class="text-center">
            <th></th>
            <th
              v-for="(coreSkill, key) in currentWeekCoreSkills"
              v-bind:key="key"
            >
              <span class="img-block">
                <img
                  :src="coreSkill.icon ? coreSkill.icon : $defaultImage"
                  :alt="coreSkill.name"
                  class="product-img"
                />
              </span>
              {{ coreSkill.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left">
              <strong>This week</strong>
            </td>
            <td
              v-for="(coreSkill, key) in currentWeekCoreSkills"
              v-bind:key="key"
              :set="
                (s = coreSkill.activities
                  ? coreSkill.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (lastWeekCoreSkills[key].activities
                      ? lastWeekCoreSkills[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  s <
                    (lastWeekCoreSkills[key].activities
                      ? lastWeekCoreSkills[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>

          <tr>
            <td class="text-left">
              <strong>Last Week</strong>
            </td>
            <td
              v-for="(coreSkill, key) in lastWeekCoreSkills"
              v-bind:key="key"
              :set="
                (a = coreSkill.activities
                  ? coreSkill.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ a }}
              <i
                v-if="
                  a >
                    (currentWeekCoreSkills[key].activities
                      ? currentWeekCoreSkills[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  a <
                    (currentWeekCoreSkills[key].activities
                      ? currentWeekCoreSkills[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <strong>Average</strong>
            </td>
            <td v-for="(coreSkill, key) in averageCoreSKills" v-bind:key="key">
              {{ getAverage(coreSkill.average ? coreSkill.average : 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>Ways of learning</h2>
    <div class="con-tablex vs-table--content border-less-table mb-10">
      <table width="100%" class="mb-5">
        <thead>
          <tr class="text-center">
            <th></th>
            <th
              v-for="(waysOfLearning, key) in currentWeekWaysOfLearnings"
              v-bind:key="key"
            >
              <span class="img-block">
                <img
                  :src="
                    waysOfLearning.icon ? waysOfLearning.icon : $defaultImage
                  "
                  :alt="waysOfLearning.name"
                  class="product-img"
                />
              </span>
              {{ waysOfLearning.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>This week</strong>
            </td>
            <td
              v-for="(waysOfLearning, key) in currentWeekWaysOfLearnings"
              v-bind:key="key"
              :set="
                (s = waysOfLearning.activities
                  ? waysOfLearning.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (lastWeekWaysOfLearnings[key].activities
                      ? lastWeekWaysOfLearnings[key].activities
                          .numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  s <
                    (lastWeekWaysOfLearnings[key].activities
                      ? lastWeekWaysOfLearnings[key].activities
                          .numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Last Week</strong>
            </td>
            <td
              v-for="(waysOfLearning, key) in lastWeekWaysOfLearnings"
              v-bind:key="key"
              :set="
                (s = waysOfLearning.activities
                  ? waysOfLearning.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (currentWeekWaysOfLearnings[key].activities
                      ? currentWeekWaysOfLearnings[key].activities
                          .numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  s <
                    (currentWeekWaysOfLearnings[key].activities
                      ? currentWeekWaysOfLearnings[key].activities
                          .numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Average</strong>
            </td>
            <td
              v-for="(waysOfLearning, key) in averageWaysOfLearnings"
              v-bind:key="key"
            >
              {{
                getAverage(waysOfLearning.average ? waysOfLearning.average : 0)
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <h2>STREAM</h2>
    <div class="con-tablex vs-table--content border-less-table">
      <table width="100%" class="mb-5">
        <thead>
          <tr class="text-center">
            <th></th>
            <th v-for="(stream, key) in currentWeekStream" v-bind:key="key">
              <span class="img-block">
                <img
                  :src="stream.icon ? stream.icon : $defaultImage"
                  :alt="stream.name"
                  class="product-img"
                />
              </span>
              {{ stream.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>This week</strong>
            </td>
            <td
              v-for="(stream, key) in currentWeekStream"
              v-bind:key="key"
              :set="
                (s = stream.activities
                  ? stream.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (lastWeekStream[key].activities
                      ? lastWeekStream[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  s <
                    (lastWeekStream[key].activities
                      ? lastWeekStream[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Last Week</strong>
            </td>
            <td
              v-for="(stream, key) in lastWeekStream"
              v-bind:key="key"
              :set="
                (s = stream.activities
                  ? stream.activities.numberOfActivities
                  : 0)
              "
              class="relative"
            >
              {{ s }}
              <i
                v-if="
                  s >
                    (currentWeekStream[key].activities
                      ? currentWeekStream[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-up fa-2x success absolute ml-3"
              ></i>
              <i
                v-if="
                  s <
                    (currentWeekStream[key].activities
                      ? currentWeekStream[key].activities.numberOfActivities
                      : 0)
                "
                class="fa fa-caret-down fa-2x danger absolute ml-3"
              ></i>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Average</strong>
            </td>
            <td v-for="(stream, key) in averageStream" v-bind:key="key">

              {{ getAverage(stream.average ? stream.average : 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </vx-card>
</template>

<script>
import axios from "@/http/axios";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      currentWeekCoreSkills: [],
      lastWeekCoreSkills: [],
      averageCoreSKills: [],
      currentWeekWaysOfLearnings: [],
      lastWeekWaysOfLearnings: [],
      averageWaysOfLearnings: [],
      currentWeekStream: [],
      lastWeekStream: [],
      averageStream: []
    };
  },
  props: {
    roomIdTracker: {},
    roomIds: {},
    teacherIds: {},
    childrenIds: {}
  },
  methods: {
    ...mapActions("centerAdmin", [
      "fetchCenterAdminCoreSkillCompare",
      "fetchCenterAdminWaysOfLearningCompare",
      "fetchCenterAdminStreamCompare"
    ]),
    ...mapActions("coreSkill", ["fetchCoreSkills"]),
    ...mapActions("waysOfLearning", ["fetchWaysOfLearning"]),
    ...mapActions("stream", ["fetchStreams"]),
    async getCoreSkills() {
      if (null != this.roomIdTracker) {
        await axios
          .get("/centerAdmin/core-skill-compare/" + this.roomIdTracker)
          .then(async res => {
            this.currentWeekCoreSkills = res.data.data.currentWeek;
            this.lastWeekCoreSkills = res.data.data.lastWeek;
            this.averageCoreSKills = res.data.data.averageWeek;
          })
          .catch(err => console.log(err));
      }
    },
    async getCoreSkills2(query) {
      await this.fetchCenterAdminCoreSkillCompare(query)
        .then(async res => {
          this.currentWeekCoreSkills = res.data.data.currentWeek;
          this.lastWeekCoreSkills = res.data.data.lastWeek;
          this.averageCoreSKills = res.data.data.averageWeek;
        })
        .catch(err => console.log(err));
    },
    async getWaysOfLearnings() {
      if (null != this.roomIdTracker) {
        await axios
          .get("/centerAdmin/ways-of-learning-compare/" + this.roomIdTracker)
          .then(async res => {
            this.currentWeekWaysOfLearnings = res.data.data.currentWeek;
            this.lastWeekWaysOfLearnings = res.data.data.lastWeek;
            this.averageWaysOfLearnings = res.data.data.averageWeek;
          })
          .catch(err => console.log(err));
      }
    },
    async getWaysOfLearnings2(query) {
      await this.fetchCenterAdminWaysOfLearningCompare(query)
        .then(async res => {
          this.currentWeekWaysOfLearnings = res.data.data.currentWeek;
          this.lastWeekWaysOfLearnings = res.data.data.lastWeek;
          this.averageWaysOfLearnings = res.data.data.averageWeek;
        })
        .catch(err => console.log(err));
    },

    async getStream() {
      if (null != this.roomIdTracker) {
        await axios
          .get("/centerAdmin/stream-compare/" + this.roomIdTracker)
          .then(async res => {
            this.currentWeekStream = res.data.data.currentWeek;
            this.lastWeekStream = res.data.data.lastWeek;
            this.averageStream = res.data.data.averageWeek;
          })
          .catch(err => console.log(err));
      }
    },
    async getStream2(query) {
      await this.fetchCenterAdminStreamCompare(query)
        .then(async res => {
          this.currentWeekStream = res.data.data.currentWeek;
          this.lastWeekStream = res.data.data.lastWeek;
          this.averageStream = res.data.data.averageWeek;
        })
        .catch(err => console.log(err));
    },

    getAverage(val) {
      return val > 0 ? Math.round(val) : 0;
    },
    async defaultAssessmentSubject() {
      // Fetch assesment subjects
      let coreSkill = await this.fetchCoreSkills();
      let waysOfLearning = await this.fetchWaysOfLearning();
      let stream = await this.fetchStreams();

      // Sorting assessment subjects by name
      this.currentWeekCoreSkills = _.sortBy(coreSkill.data.data, [
        function(o) {
          return o.name;
        }
      ]);
      this.lastWeekCoreSkills = this.currentWeekCoreSkills;
      this.averageCoreSKills = this.currentWeekCoreSkills;

      this.currentWeekWaysOfLearnings = _.sortBy(waysOfLearning.data.data, [
        function(o) {
          return o.name;
        }
      ]);
      this.lastWeekWaysOfLearnings = this.currentWeekWaysOfLearnings;
      this.averageWaysOfLearnings = this.currentWeekWaysOfLearnings;

      this.currentWeekStream = _.sortBy(stream.data.data, [
        function(o) {
          return o.name;
        }
      ]);
      this.lastWeekStream = this.currentWeekStream;
      this.averageStream = this.currentWeekStream;
    }
  },
  created() {
    this.getCoreSkills();
    this.getWaysOfLearnings();
    this.getStream();
  },
  computed: {
    childId() {
      return this.$store.state.child.selectedChildId;
    }
  },
  watch: {
    roomIdTracker(obj) {
      this.roomIdTracker = obj;
      this.getCoreSkills();
      this.getWaysOfLearnings();
      this.getStream();
    },
    roomIds(res) {
      if (res.length > 0) {
        let data = {
          roomIds: res
        };
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      } else {
        this.defaultAssessmentSubject();
      }
    },
    teacherIds(res) {
      if (this.roomIds.length > 0) {
        let data = {
          roomIds: this.roomIds,
          teacherIds: res
        };
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      }
    },
    childrenIds(res) {
      if (this.roomIds.length > 0) {
        let data = {
          roomIds: this.roomIds,
          teacherIds: this.teacherIds,
          childrenIds: res
        };
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      }
    }
  }
};
</script>
<style>
.success {
  color: green;
}

.danger {
  color: red;
}
</style>
