<template>
  <div>
    <!--Assessment Subjects Block-->
    <vs-row class="mb-5 mx-10 w-auto">
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg mb-5 md:mb-0">
        <vx-card class="h-full mx-0 xl:m-0 xl:mr-5 xl:py-10">
          <h2>Core Skills</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg mb-5 md:mb-0">
        <vx-card class="h-full mx-0 xl:m-0 xl:mx-5 xl:py-10">
          <h2>Ways Of Learning</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="waysOfLearningChart.chartOptions"
            :series="waysOfLearningChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
      <vs-col class="text-center w-full xl:w-1/3 rounded-lg ">
        <vx-card class="h-full mx-0 xl:m-0 xl:ml-5 xl:py-10">
          <h2>STREAM</h2>
          <vue-apex-charts
            type="donut"
            height="300"
            :options="streamChart.chartOptions"
            :series="streamChart.series"
          ></vue-apex-charts>
        </vx-card>
      </vs-col>
    </vs-row>
    <tracker
      :roomIdTracker="roomIdTracker"
      :roomIds="roomIds"
      :teacherIds="teacherIds"
      :childrenIds="childrenIds"
    ></tracker>

    <assessment-subject-compare
      :roomIdTracker="roomIdTracker"
      :roomIds="roomIds"
      :teacherIds="teacherIds"
      :childrenIds="childrenIds"
    ></assessment-subject-compare>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import axios from "../../../../http/axios/index";
import { mapActions } from "vuex";
import AssessmentSubjectCompare from "./AssessmentSubjectCompare";
import Tracker from "./Tracker";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: { VueApexCharts, Tracker, AssessmentSubjectCompare },
  data() {
    return {
      themeColors: [
        "#7F84BF",
        "#D785B7",
        "#F2956B",
        "#F2D06C",
        "#73C9C1",
        "#F16B6B",
        "#80BEE7",
        "#557C96",
        "#EEC1DD"
      ],
      donutChart: {
        series: [],
        chartOptions: {}
      },
      waysOfLearningChart: {
        series: [],
        chartOptions: {}
      },
      streamChart: {
        series: [],
        chartOptions: {}
      },
      roomIdTracker: null
    };
  },
  props: {
    roomId: {},
    childId: {},
    roomIds: {},
    teacherIds: {},
    childrenIds: {}
  },
  watch: {
    stateRooms(a) {
      let data = {
        roomIds: a
      };

      data = JSON.stringify(data);
      this.getCoreSkills2(data);
      this.getWaysOfLearnings2(data);
      this.getStream2(data);
    },
    async childId(res) {
      this.childId = res;
      this.$vs.loading();

      await this.getCoreSkills(res);
      await this.getWaysOfLearnings(res);
      await this.getStream(res);
      this.$vs.loading.close();
    },
    roomId(obj) {
      this.roomIdTracker = obj;
      this.getCoreSkills();
      this.getWaysOfLearnings();
      this.getStream();
    },
    roomIds(res) {
      let data = {
        roomIds: res
      };
      if (res.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      } else {
        this.defaultDonutChart();
      }
    },
    teacherIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: res
      };
      if (this.roomIds.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      }
    },
    childrenIds(res) {
      let data = {
        roomIds: this.roomIds,
        teacherIds: this.teacherIds,
        childrenIds: res
      };
      if (this.roomIds.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      }
    }
  },
  methods: {
    ...mapActions("child", ["setSelectedChildId"]),
    ...mapActions("centerAdmin", [
      "fetchCoreSkillCount",
      "fetchWaysOfLearningCount",
      "fetchStreamCount"
    ]),
    async getCoreSkills(childId = null) {
      this.$vs.loading();
      let param = childId ? "?childId=" + childId : "";
      if (null != this.roomId) {
        await axios
          .get("centerAdmin/core-skill-activity-count/" + this.roomId + param)
          .then(async res => {
            let coreSkills = res.data.data;
            let a = coreSkills.map(item => {
              return item.activities ? item.activities.numberOfActivities : 0;
            });
            let b = await coreSkills.map(item => {
              return item.name;
            });
            this.donutChart.series = a;
            this.donutChart.chartOptions = {
              labels: b,
              colors: this.themeColors,
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: false
              },
              // responsive: [
              //   {
              //     breakpoint: 992,
              //     options: {
              //       chart: {
              //         // width: 200
              //       },
              //       legend: {
              //         position: "bottom"
              //       }
              //     }
              //   }
              // ]
            };
            this.$set(this.donutChart);
            setTimeout(() => {
              this.$vs.loading.close();
            }, 2000);
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.loading.close();

            if (err.response.status === 403) {
              this.swalMessage();
            }
            console.log(err);
          });
      }
    },
    async getCoreSkills2(query) {
      await this.fetchCoreSkillCount(query)
        .then(async res => {
          let coreSkills = res.data.data;
          let a = coreSkills.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await coreSkills.map(item => {
            return item.name;
          });
          this.donutChart.series = a;
          this.donutChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            },
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 200
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.donutChart);
        })
        .catch(err => {
          if (err.response.status === 403) {
            this.swalMessage();
          }
          console.log(err);
        });
    },
    async getWaysOfLearnings(childId = null) {
      let param = childId ? "?childId=" + childId : "";

      if (null != this.roomId) {
        await axios
          .get(
            "centerAdmin/ways-of-learning-activity-count/" + this.roomId + param
          )
          .then(async res => {
            let waysOfLearnings = res.data.data;
            let a = waysOfLearnings.map(item => {
              return item.activities ? item.activities.numberOfActivities : 0;
            });
            let b = await waysOfLearnings.map(item => {
              return item.name;
            });
            this.waysOfLearningChart.series = a;
            this.waysOfLearningChart.chartOptions = {
              labels: b,
              colors: this.themeColors,
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: false
              },
              // responsive: [
              //   {
              //     breakpoint: 992,
              //     options: {
              //       chart: {
              //         // width: 200
              //       },
              //       legend: {
              //         position: "bottom"
              //       }
              //     }
              //   }
              // ]
            };
            this.$set(this.waysOfLearningChart);
          })
          .catch(err => {
            this.$vs.loading.close();

            if (err.response.status === 403) {
              this.swalMessage();
            }
            console.log(err);
          });
      }
    },
    async getWaysOfLearnings2(query) {
      await this.fetchWaysOfLearningCount(query)
        .then(async res => {
          let waysOfLearnings = res.data.data;
          let a = waysOfLearnings.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await waysOfLearnings.map(item => {
            return item.name;
          });
          this.waysOfLearningChart.series = a;
          this.waysOfLearningChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            },
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 200
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.waysOfLearningChart);
        })
        .catch(err => {
          this.$vs.loading.close();

          if (err.response.status === 403) {
            this.swalMessage();
          }
          console.log(err);
        });
    },
    swalMessage() {
      Swal.fire({
        title: "No Access",
        html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33"
      }).then(result => {
        this.$router.push("/center-admin/dashboard");
      });
    },
    async getStream(childId = null) {
      let param = childId ? "?childId=" + childId : "";

      if (null != this.roomId) {
        await axios
          .get("centerAdmin/stream-activity-count/" + this.roomId + param)
          .then(async res => {
            let stream = res.data.data;
            let a = stream.map(item => {
              return item.activities ? item.activities.numberOfActivities : 0;
            });
            let b = await stream.map(item => {
              return item.name;
            });
            this.streamChart.series = a;
            this.streamChart.chartOptions = {
              labels: b,
              colors: this.themeColors,
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: false
              },
              // responsive: [
              //   {
              //     breakpoint: 992,
              //     options: {
              //       chart: {
              //         // width: 200
              //       },
              //       legend: {
              //         position: "bottom"
              //       }
              //     }
              //   }
              // ]
            };
            this.$set(this.streamChart);
          })
          .catch(err => console.log(err));
      }
    },
    async getStream2(query) {
      await this.fetchStreamCount(query)
        .then(async res => {
          let stream = res.data.data;
          let a = stream.map(item => {
            return item.activities ? item.activities.numberOfActivities : 0;
          });
          let b = await stream.map(item => {
            return item.name;
          });
          this.streamChart.series = a;
          this.streamChart.chartOptions = {
            labels: b,
            colors: this.themeColors,
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: false
            },
            // responsive: [
            //   {
            //     breakpoint: 992,
            //     options: {
            //       chart: {
            //         // width: 200
            //       },
            //       legend: {
            //         position: "bottom"
            //       }
            //     }
            //   }
            // ]
          };
          this.$set(this.streamChart);
        })
        .catch(err => console.log(err));
    },
    defaultDonutChart() {
      this.donutChart.series = [];

      this.donutChart.chartOptions = {
        labels: [],
        colors: this.themeColors,
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false
        },
        // responsive: [
        //   {
        //     breakpoint: 992,
        //     options: {
        //       chart: {
        //         // width: 325
        //       },
        //       legend: {
        //         position: "bottom"
        //       }
        //     }
        //   }
        // ]
      };
      this.waysOfLearningChart = this.donutChart;
      this.streamChart = this.donutChart;
      this.$set(this.donutChart);
      this.$set(this.waysOfLearningChart);
      this.$set(this.streamChart);
    }
  },
  created() {
    this.roomIdTracker = this.roomId;
    // this.getCoreSkills();
    // this.getWaysOfLearnings();
    // this.getStream();


      let data = {
        roomIds: this.roomIds,
        teacherIds: this.teacherIds,
        childrenIds: this.childrenIds
      };
      if (this.roomIds.length > 0) {
        data = JSON.stringify(data);
        this.getCoreSkills2(data);
        this.getWaysOfLearnings2(data);
        this.getStream2(data);
      }


  },
  computed: {
    stateRooms() {
      return this.$store.state.room.selectedRooms;
    }
  }
};
</script>
<style lang="scss">
.apexcharts-legend {
  display: none !important;
}
</style>
